/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-icon {
  pointer-events: none;
}

.resizecards{

    max-width: 700px;
    margin-bottom: 8px;
    width: 60vw;
    height: auto;
  
}



.flex{
  display: flex;
}

.justify-content-center{
  justify-content: center;
}

.align-items-center{
  align-items: center;
}

.flex-row {

  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

ion-title {
  position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  float: right !important;
  //font-weight: 300;
  font-size: 200%;
}

ion-toolbar {
  --background: linear-gradient(to right , rgba(0, 89, 255, 0.712), rgb(19, 0, 128));
}


.banner-text {
  font-style: italic;
}



.toolbar-logo{
    width:50px !important;
    height: 50px !important;
    border-radius: 50%;
    border:5px solid whitesmoke;
    float: right;
    filter: brightness(100%) !important;
    //margin-right: 3%;
}


.banner-text {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 7vh;
  text-align: center;
  width: 80%;

  color: white;
  font-size: 280%;
}

.banner-img {
  width: 100%;
  height: 20vh;
  object-fit: cover;
  filter: brightness(50%);
}

.form-card {
  margin-top: 5%;
  width: 80vw;
  height: 75vh;
  overflow-y: scroll;
}
.cardy{
  padding: 0px;
}
.update-button {
  float: right;
  padding-right: 7px;
}

.newcolour{
  color: black;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}
